.heading {
  font-size: 4rem; /* reduced font size */
  text-align: center;
  margin: 3rem 0; /* reduced margin */
}

.education {
  padding: 50px 10px; /* reduced padding */
  background: var(--bg-color);
}

.education h2 {
  margin-bottom: 3rem; /* reduced margin */
}

.timeline-items {
  max-width: 900px; /* reduced max-width */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.timeline-items::before {
  content: "";
  position: absolute;
  width: 3px; /* reduced width */
  height: 100%;
  background-color: #00ffee;
  left: calc(50% - 1px);
}

.timeline-item {
  margin-bottom: 30px; /* reduced margin */
  width: 100%;
  position: relative;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-item:nth-child(odd) {
  padding-right: calc(50% + 20px); /* reduced padding */
  text-align: right;
}

.timeline-item:nth-child(even) {
  padding-left: calc(50% + 20px); /* reduced padding */
}

.timeline-dot {
  height: 15px; /* reduced size */
  width: 15px; /* reduced size */
  background-color: #00ffee;
  box-shadow: 0 0 15px #00ffee, 0 0 30px #00ffee; /* reduced shadow */

  position: absolute;
  left: calc(50% - 8px);
  border-radius: 50%;
  top: 10px;
}

.timeline-date {
  font-size: 14px; /* reduced font size */
  font-weight: 800;
  color: white;
  margin: 4px 0 10px; /* reduced margin */
}

.timeline-content {
  background-color: var(--bg-color);
  border: 2px solid #00ffee; /* reduced border size */
  padding: 20px 30px; /* reduced padding */
  border-radius: 2rem; /* reduced border radius */
  box-shadow: 0 0 5px #00ffee; /* reduced shadow */
  cursor: pointer;
  /* transition: 0.3s ease-in-out; commented out transition */
}

/* .timeline-content:hover {
  transform: scale(1.05);
  box-shadow: 0 0 25px #00ffee;
} */

.timeline-content h3 {
  font-size: 16px; /* reduced font size */
  color: white;
  margin: 0 0 8px; /* reduced margin */
  font-weight: 500;
}

.timeline-content p {
  color: white;
  font-size: 12px; /* reduced font size */
  font-weight: 300;
  line-height: 18px; /* reduced line-height */
}

::-webkit-scrollbar {
  width: 10px; /* reduced width */
}

::-webkit-scrollbar-thumb {
  background-color: #00ffee;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-color);
}
